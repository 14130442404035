import api from "./api";

export default {
    getMarcas(tipo) {
        return api.get(`/fipe/tipo/${tipo}/marcas`);
    },

    getModelos(tipo, marca) {
        return api.get(`/fipe/tipo/${tipo}/marcas/${marca}/modelos`);
    },

    getAnos(tipo, marca, modelo) {
        return api.get(`/fipe/tipo/${tipo}/marcas/${marca}/modelos/${modelo}/anos`);
    },

    getVeiculo(tipo, marca, modelo, ano) {
        return api.get(`/fipe/tipo/${tipo}/marcas/${marca}/modelos/${modelo}/anos/${ano}`);
    },
};
