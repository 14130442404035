import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

import Veiculo from "@/pages/Veiculos";
import Cliente from "@/pages/Cliente";
import Cotacao from "@/pages/Cotacao";
import Fechamento from "@/pages/Fechamento";

const routes = [
    {
        path: "/:usuario_hash?/simulacao",
        meta: {
            header: true,
        },
        name: 'simulacao',
        component: Veiculo,
    },
    {
        path: "/:usuario_hash?/cliente",
        meta: {
            header: true,
        },
        name: "cliente",
        component: Cliente,
    },
    {
        path: "/:usuario_hash?/cotacao",
        meta: {
            header: true,
        },
        name: "cotacao",
        component: Cotacao,
    },
    {
        path: "/:usuario_hash?/fechamento",
        meta: {
            header: true,
        },
        name: "fechamento",
        component: Fechamento,
    },
];

const router = new VueRouter({
    routes,
    mode: "history",
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(route => route.meta.requerLogin)) {
        if (store.state.auth.logged) {
            next();
        } else {
            await store.dispatch("loadUsuarioLogado").then(() => {
                if (store.state.auth.logged) {
                    next();
                } else {
                    next("/login");
                }
            });
        }
    } else {
        next();
    }
});

export default router;
