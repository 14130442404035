import { render, staticRenderFns } from "./Fechamento.vue?vue&type=template&id=cba3cfe6&scoped=true&"
import script from "./Fechamento.vue?vue&type=script&lang=js&"
export * from "./Fechamento.vue?vue&type=script&lang=js&"
import style0 from "./Fechamento.vue?vue&type=style&index=0&id=cba3cfe6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cba3cfe6",
  null
  
)

export default component.exports