<template>
    <main>
        <div class="container-md">
            <div class="row">
                <div class="col-md-3 col-md-offset-3"></div>
                <div class="col-md-6">
                    <form>
                        <h4 class="mt-4 mb-4">Informe os dados para cotação</h4>
                        <div class="form-group">
                            <b-form-radio-group v-model="tipo" id="radio-group-2" name="tipo">
                                <b-form-radio v-for="tipo in tipos" :value="tipo.id" :key="`tipo-${tipo.id}`">
                                    {{ tipo.descricao }}
                                </b-form-radio>
                            </b-form-radio-group>
                        </div>
                        <Carro v-if="false" />
                        <Moto v-else-if="false" />
                        <Caminhao />
                    </form>
                </div>
                <div class="col-md-3 col-md-offset-3"></div>
            </div>
        </div>
    </main>
</template>

<script>
    import Carro from "./Carro.vue";
    import Caminhao from "./Caminhao.vue";
    import Moto from "./Moto.vue";
    import api from "@/services/api";

    export default {
        name: "Veiculos",
        components: {
            Carro,
            Caminhao,
            Moto,
        },
        data() {
            return {
                tipos: [],
            };
        },
        computed: {
            tipo: {
                get() {
                    return this.$store.state.cotacao.veiculo_tipo_id;
                },
                set(value) {
                    this.$store.dispatch("setTipo", { flag: true, label: value });
                },
            },
        },
        beforeMount() {
            api.get("veiculo-tipo").then(res => (this.tipos = res.data));
            this.$store.dispatch("loadEstados");
            if (localStorage.getItem("tipo")) {
                this.$store.dispatch("setTipo", { flag: false, label: Number(localStorage.getItem("tipo")) });
            } else {
                localStorage.setItem("tipo", 3);
                this.$store.dispatch("setTipo", { flag: true, label: 3 });
            }
        },
        watch: {
            tipo(newTipo) {
                localStorage.setItem("tipo", newTipo);
            },
        },
    };
</script>

<style scoped>
    h4 {
        margin-top: 2rem;
    }

    .icon-message {
        width: 50px;
        height: 80px;
        color: var(--background-second);
    }
</style>
