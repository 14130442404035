import api from "./api";

export default {
    get(id) {
        return api.get(`/cotacoes/${id}`);
    },

    store(data) {
        return api.post(`/cotacoes`, data);
    },

    update(id, data) {
        return api.get(`/cotacoes/${id}`, data);
    },
};
