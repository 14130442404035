<template>
    <header>
        <div class="container">
            <div class="row p-row">
                <div class="col-md-2 col-md-offset-2">
                    <b-button
                        variant="link"
                        class="btn-voltar"
                        @click="goTo() ? $router.go(-1) : $router.push('/')"
                        v-if="!$route.matched.some(({ path }) => path === '')"
                    >
                        <b-icon icon="arrow-left-square" variant="light" font-scale="2"></b-icon>
                    </b-button>
                </div>
                <div class="col-md-8 box-logo">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="logo">
                                <img src="../assets/logo/logo-proteauto.png" alt="Proteauto" class="img-responsive" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="title">
                                <p>Seu veículo</p>
                                <p>Protegido 24H</p>
                                <ul class="icones">
                                    <li><b-icon icon="facebook" font-scale="0.5"></b-icon></li>
                                    <li><b-icon icon="instagram" font-scale="0.5"></b-icon></li>
                                    <li><b-icon icon="linkedin" font-scale="0.5"></b-icon></li>
                                    <li><b>proteautobrasil</b></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-md-offset-2"></div>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: "Header",
        methods: {
            goTo() {
                return window.history.length > 2;
            },
        },
    };
</script>

<style scoped>
    header {
        background: var(--background-primary);
        border-bottom: 5px solid var(--background-second);
        width: 100%;
    }

    .title {
        float: left;
    }

    .img-responsive {
        width: 100%;
        max-width: 300px;
        height: auto;
    }

    .p-row {
        padding: 1rem 0;
    }

    .title {
        text-transform: uppercase;
        font-size: 40px;
        font-weight: 800;
        line-height: 32px;
        letter-spacing: -1px;
        margin-top: 3rem;
    }

    .title p:nth-of-type(1) {
        color: #fff;
    }

    .title p:nth-of-type(2) {
        color: #f1c41f;
    }

    ul.icones {
        list-style: none;
        display: flex;
        color: #fff;
        padding-left: 0;
    }

    ul.icones li {
        padding: 0 8px;
    }

    ul.icones li b {
        font-size: 16px;
        letter-spacing: 2px;
    }

    @media (max-width: 576px) {
        .logo img {
            margin: 0 auto;
        }

        .title {
            display: none;
        }

        .box-logo {
            text-align: center;
        }

        .img-responsive {
            max-width: 150px;
        }
    }
</style>
