export default {
    loading: {
        full: false,
        page: false,
        save: false,
        marcas: false,
        modelos: false,
        anos: false,
        fipe: false,
        estados: false,
        implementos: false,
    },

    cotacao: {
        veiculo_tipo_id: 3,
        pesado: false,
        marca_id: null,
        modelo_id: null,
        ano_id: null,
        fipe: null,
        valor_fipe: null,
        implemento_id: null,
        valor_implemento: null,
        nome: null,
        email: null,
        telefone: null,
        estado_id: null,
        blacklist: false,
        plano_estado_id: null,
    },

    simulacao: {
        implemento: false,
    },

    erros: {
        marca_id: [],
        modelo_id: [],
        ano_id: [],
        fipe: [],
        valor_fipe: [],

        implemento_id: [],
        valor_implemento: [],

        nome: [],
        email: [],
        senha: [],
        login: [],
        telefone: [],
        estado_id: [],
        error_blacklist: [],
    },

    marcas: [],
    modelos: [],
    anos: [],
    estados: [],
    implementos: [],
    adicionais: [],

    fipe: {},
    auth: {
        logged: false,
        usuario: {},
    },
    usuario: {},

    plano_estados: [],

    data_table: {
        showModal: false,
        item: {}
    }
};
