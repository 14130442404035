<template>
    <div class="form-group">
        <div class="v-input" :class="{ 'v-input-disabled': modelos.length === 0 }">
            <fieldset class="fieldset" :class="{ 'error-field': invalid }">
                <legend for="modelo"><span>Modelo</span></legend>
                <div class="v-select">
                    <v-select
                        v-model="modelo"
                        id="modelo"
                        :loading="loading"
                        label="name"
                        :clearable="true"
                        :disabled="modelos.length === 0"
                        placeholder="Selecione uma modelo"
                        :reduce="opt => opt.id"
                        :options="modelos"
                    >
                        <div slot="no-options">Modelo não encontrado</div>
                    </v-select>
                </div>
            </fieldset>
        </div>
        <b-form-invalid-feedback :force-show="invalid">
            <div v-for="(erro, index) in erros" :key="`error-modelo-${index}`">
                {{ erro }}
            </div>
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    export default {
        name: "Modelo",
        computed: {
            loading() {
                return this.$store.state.loading.modelos;
            },
            erros() {
                return this.$store.state.erros.modelo_id;
            },
            invalid() {
                return this.erros.length > 0;
            },
            modelos() {
                return this.$store.state.modelos;
            },
            modelo: {
                get() {
                    return this.$store.state.cotacao.modelo_id;
                },
                set(value) {
                    return this.$store.dispatch("setModelo", value);
                },
            },
        },
        mounted() {
            if (localStorage.getItem("modelo")) {
                this.$store.dispatch("setModelo", Number(localStorage.getItem("modelo")));
            }
        },
        watch: {
            modelo(newModelo) {
                if (newModelo) {
                    localStorage.setItem("modelo", newModelo);
                } else {
                    localStorage.removeItem("modelo");
                }
            },
        },
    };
</script>
