import axios from "axios";
import api from "./api";

export default {
    async getCSRF() {
        await axios.get(process.env.VUE_APP_SANCTUM_URL, {
            withCredentials: true,
        });
    },
    async login(data) {
        return await this.getCSRF().then(() => {
            return api.post("/login", data);
        });
    },
    async logout() {
        return await api.get("/logout");
    },
    async getUsuario() {
        return await api.get("/usuario/logged");
    },
};
